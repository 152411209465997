import React from "react";
import { FunctionComponent } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FeaturesGrid } from "../components/features-grid/FeaturesGrid";
import { FeaturesGrid3 } from "../components/features-grid/FeaturesGrid3";
import { HeroCta } from "../components/cta/HeroCta";
import Keyword from "../components/typography/Keyword";
import PageLayout from "../components/page-layout/PageLayout";
import GridBlock from "../components/grid-block/GridBlock";
import SEO from "../components/SEO";
import KeywordLink from "../components/typography/KeywordLink";
import ImageHero3 from "../components/hero/ImageHero3";
import { DisclosureProcess } from "../components/DisclosureProcess";
import { useServiceTree } from "../hooks/useServiceTree";
import { useServiceI18nLink, useUseCaseI18nLink } from "../hooks/useI18nLink";
import TechIcon from "../components/icons/TechIcon";
import ImageCta from "../components/cta/ImageCta";
import ImageTextSection from "../components/text/ImageTextSection";
import HtmlTrans from "../components/typography/HtmlTrans";
import { useServiceUseCases } from "../hooks/useUseCases";
import { useI18next } from "gatsby-plugin-react-i18next";
import { LinkCta } from "../components/typography/LinkCta";

interface Service {
  id: string;
  slug: string;
  datePublished: string;
  dateModified: string;
  image: string;
  image2: string;
}

interface ServiceTemplateProps {
  service: Service;
}

const ServiceTemplate: FunctionComponent<ServiceTemplateProps> = ({
  service,
}) => {
  const { t, i18n } = useTranslation();
  const services = useServiceTree();
  const { serviceLink } = useServiceI18nLink();
  const { language } = useI18next();
  const { useCaseLink } = useUseCaseI18nLink();
  const useCases = useServiceUseCases(service.id);

  const image = require(
    `../assets/images/services/${service.image}.svg`,
  ).default;

  const targets: Record<string, string> = t(
    `services:${service.id}.targets.list`,
    {
      returnObjects: true,
    },
  );

  const benefits: Array<{
    icon: string;
    name: string;
    description: JSX.Element;
  }> = (
    t(`services:${service.id}.benefits.items`, {
      returnObjects: true,
    }) as {
      icon: string;
      name: string;
      description: string;
    }[]
  ).map((benefit) => {
    return {
      icon: benefit.icon,
      name: benefit.name,
      description: <HtmlTrans i18nKey={benefit.description} />,
    };
  });

  const solutions = services.find((s) => s.id === service.id)?.solutions;
  const otherService = services.filter((s) => s.id !== service.id);

  const processSteps: Array<{
    title: string;
    description: JSX.Element;
  }> = (
    t(`services:${service.id}.process.steps`, {
      returnObjects: true,
    }) as {
      title: string;
      description: string;
    }[]
  ).map((step) => {
    return {
      title: step.title,
      description: <HtmlTrans i18nKey={step.description} />,
    };
  });

  return (
    <PageLayout pageName={service.slug} headerBg="gray-100">
      <SEO
        titleI18nKey={t(`services:${service.id}.meta.title`)!}
        descriptionI18nKey={t(`services:${service.id}.meta.description`)!}
        image={image}
        datePublished={new Date(service.datePublished)}
        dateModified={new Date(service.dateModified)}
      />

      <ImageHero3
        tag={t(`services:${service.id}.tag`)}
        title={<HtmlTrans i18nKey={t(`services:${service.id}.title`)} />}
        description={
          <HtmlTrans i18nKey={`services:${service.id}.description`} />
        }
        image={image}
      />

      {/* HOW IT WORK */}
      <ImageTextSection
        tag={t(`services:${service.id}.howItWorks.tag`)}
        title={t(`services:${service.id}.howItWorks.title`)}
        text={<HtmlTrans i18nKey={`services:${service.id}.howItWorks.text`} />}
        image={require(`../assets/images/${service.image2}.jpg`).default}
        imagePosition="object-right"
        theme="gray"
      >
        <HeroCta />
      </ImageTextSection>

      {/* TARGETS */}
      <GridBlock
        tag={t(`services:target.tag`)}
        title={t(`services:target.title`)}
        description={
          i18n.exists(`services:${service.id}.targets.description`) ? (
            <HtmlTrans i18nKey={`services:${service.id}.targets.description`} />
          ) : (
            ""
          )
        }
        lg3={false}
        theme="white"
        noMargin="top"
        blocks={Object.keys(targets).map((target) => (
          <Keyword
            title={t(`services:target.targets.${target}`)}
            description={<HtmlTrans i18nKey={targets[target]} />}
          />
        ))}
      />

      {/* VANTAGGI */}
      <FeaturesGrid
        tag={t("services:benefits.tag")}
        title={t(`services:${service.id}.benefits.title`)}
        description={
          i18n.exists(`services:${service.id}.benefits.description`) && (
            <HtmlTrans
              i18nKey={`services:${service.id}.benefits.description`}
            />
          )
        }
        elements={benefits}
        theme="gray"
      />

      {/* CTA */}
      <ImageCta
        tag={t(`services:pageCta.tag`)}
        title={t(`services:pageCta.title`)}
        description={<Trans i18nKey={`services:${service.id}.cta`} />}
        image={require("../assets/images/astrorei-cta.jpg").default}
      />

      {/* PROCESS */}
      <DisclosureProcess
        tag={t(`services:${service.id}.process.tag`)}
        title={t(`services:${service.id}.process.title`)}
        steps={processSteps}
        left={false}
        theme="white"
      />

      {/* SOLUTIONS */}
      {solutions && (
        <FeaturesGrid3
          tag={t(`services:${service.id}.solutions.tag`)}
          title={t(`services:${service.id}.solutions.title`)}
          elements={solutions.map((solution) => ({
            name: t(`solutions:${solution.id}.name`),
            to: serviceLink(solution),
            icon: (
              <TechIcon
                size="xl"
                icon={solution.icon}
                className="text-gray-900 overflow-hidden"
              />
            ),
          }))}
        />
      )}

      {/* USE CASES */}
      {useCases && useCases.length > 0 && (
        <GridBlock
          tag={t(`index:useCase.tag`)}
          title={t(`index:useCase.title`)}
          description={t(`index:useCase.description`)}
          lg3={false}
          noMargin="top"
          blocks={useCases.slice(0, 4).map((useCase) => (
            <>
              <Keyword
                title={useCase.hero.projectName[language]}
                description={useCase.hero.projectName[language]}
              />
              <LinkCta
                to={useCaseLink(useCase)}
                text={t("index:useCase.ctaLink")}
                align="right"
              />
            </>
          ))}
        />
      )}

      {/* OTHER SERVICES */}
      <GridBlock
        tag={t(`services:otherService.tag`)}
        title={t(`services:otherService.title`)}
        lg3={false}
        noMargin="bottom"
        blocks={otherService.map((s) => (
          <div className="py-6">
            <KeywordLink
              title={t(`services:${s.id}.name`)}
              to={serviceLink(s)}
            />
          </div>
        ))}
      />
    </PageLayout>
  );
};

export default ServiceTemplate;
